<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="please choose" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>“UniLive” Live Broadcast Agreement</h2>
		<p>The "UniLive Live Broadcast Agreement" is an agreement between Pix Technology PTE. LTD. (hereinafter
			referred to as "we") and you (you are a natural person, legal person or other organization) that regulates the
			rights and obligations of both parties. A legally binding electronic agreement, hereinafter referred to as "this
			Agreement". By checking "I agree" or clicking the "I have read and abide by this agreement" button, it means that
			you have carefully read, fully understood and fully accepted all the terms of this agreement without reservation.
		</p>
		<h3>Uni Live User Live Streaming Basic Rules</h3>
		<p>
			Live Streaming Content
		</p>
		<p>
			1. Illegal and Harmful Information  
			It is prohibited to spread content related to politically sensitive topics, ethnic separatism, religious extremism, etc.  
			Displaying violence, gore, terror, gambling, drugs, or any illegal activities is strictly forbidden.  
			The promotion of feudal superstitions (e.g., fortune-telling, divination) should be approached with caution and not excessively promoted.  
		</p>
		<p>
			2. Pornographic and Vulgar Content  
			Nudity, sexual implications, vulgar performances, or any form of pornographic content are strictly prohibited.  
			Clothing must not be overly revealing (e.g., exposing breasts, buttocks, or wearing sheer outfits), or the live stream may be interrupted.  
			Provocative language or behavior involving sexual topics is not allowed.  
		</p>
		<p>
			3. Infringement of Others’ Rights  
			Unauthorized disclosure of others’ private information (e.g., name, address) is prohibited.  
			Cyberbullying, malicious attacks, or defamation of others are strictly forbidden.  
		</p>
		<p>
			4. Fraudulent and Deceptive Behavior  
			Spreading rumors, false advertisements, or unverified information (e.g., exaggerating the effectiveness of health products) is not allowed.  
			Fraud, pyramid schemes, or inducing users to conduct private transactions are strictly prohibited.  
		</p>
		<p>
			5. Content Involving Minors  
			Minors must be strictly protected. Children are not allowed to participate in live streaming.  
			Content that may harm the physical or mental health of minors must not be displayed.  
		</p>

		<h3>Uni Live Basic Rules for Video Uploads</h3>
		<p>
			Content Guidelines (Community Standards)
		</p>
		<p>
			Prohibited Violent Content: Videos that depict violence, harm, abuse, or terrorism-related content, including real or simulated violent acts, are not allowed.  
		</p>
		<p>
			Prohibited Pornographic or Explicit Content: Nudity, sexual implications, pornography, or any form of adult content is strictly forbidden.  
		</p>

		<p>
			Prohibited Hate Speech: Content that discriminates against, attacks, or incites hatred toward groups based on race, religion, gender, sexual orientation, nationality, etc., is not allowed.  
		</p>
		<p>
			Prohibited Bullying and Harassment: Uploading content that humiliates, threatens, or harasses others, including inappropriate behavior toward children, is prohibited.  
		</p>
		<p>
			Prohibited Dangerous Acts: Videos that showcase dangerous challenges, pranks, or illegal activities (e.g., drug use, weapon handling) that may lead to harm are not allowed.  
		</p>

		<h3>Article 1 General Provisions</h3>
		<p>1. The UniLive platform refers to the live broadcast platform owned by Pix Technology PTE. LTD. and
			its affiliated companies including but not limited to Pix Technology PTE. LTD. In accordance with our
			registration requirements and rules, you apply to become our live broadcast service provider (or "live broadcast
			party") on our legally operated UniLive platform (hereinafter referred to as the "Platform") to provide our
			platform users with For live broadcast services of online live video content, the period during which you provide
			services on our platform shall be deemed to be within the agreement period. We do not review in advance the
			aforementioned uploaded video content that you participated in, edited, or produced, nor do we take the initiative
			to edit, organize, modify, or process such videos. </p>
		<p>2. Before signing this agreement, you have fully understood our rules and requirements, and are qualified,
			capable, and qualified to perform the responsibilities and obligations of the live broadcast party stipulated in
			this agreement. This Agreement constitutes a valid, binding, enforceable legal obligation on you. You are clearly
			aware of all terms and definitions under this Agreement and have no doubts about it. </p>
		<p>3. You promise and declare that you will comply with the relevant provisions of local laws when providing
			services to us, and you shall not engage in other behavior that violates the laws of China and the locality in the
			name of performing this agreement. </p>
		<p>4. You and we do not constitute any employment, labor, or service relationship at the labor legal level, and we
			do not need to pay you social insurance premiums and benefits. </p>
		<p>5. You shall not engage in any live broadcast-related activities on third-party competitive platforms without our
			prior written consent. </p>
		<p>6. Before registering as a UniLive anchor or performing live broadcast services on the UniLive platform, you have
			confirmed that you have not signed or has a valid existing exclusive live broadcast agreement with any third-party
			platform. </p>
		<p>7. During the cooperation between the two parties, your rights to live broadcast, upload, publish or transmit
			content shall be subject to the terms of the "UniLive User Service Agreement". </p>

		<h3>Article 2 Our Rights and Obligations</h3>
		<p>1. We have the right to formulate the platform operation system and the management rules for the live broadcast
			parties, and make them part of this agreement. We have the right to manage and supervise you, and we have the
			right to make corresponding rules based on the operation conditions. Adjustment or change, you express your
			understanding and agreement to this. </p>
		<p>2. We have the right to inspect and evaluate you to establish (cancel) rewards or penalties for you. The specific
			inspection items and standards will be formulated separately by us, without additional consent from you. If we
			request to sign a formal live broadcast cooperation agreement with you separately, you shall not refuse or refuse
			to sign in any other way [otherwise you shall pay us a one-time liquidated damages of [50,000] (capital: five
			thousand) yuan] . </p>
		<p>3. We have the right to put forward suggestions and opinions for improving your live broadcast service. You
			should make corresponding rectifications within [3] working days after receiving our suggestions and opinions,
			otherwise we will have The right to restrict, close, recycle, or terminate your use of the UniLive live broadcast
			room. Corresponding deductions from the service fees payable to you (if any) may cause you certain losses, which
			losses will be borne by you. , we do not assume any responsibility. </p>
		<p>4. We have the final right to make the final decision on matters related to your live broadcast. </p>
		<p>5. We have the right to use your name (including but not limited to your real name, pen name, screen name,
			previous name and any text symbols representing your identity), portrait (including but not limited to portraits
			of real people and cartoon portraits, etc.) to carry out various promotions on our platform. </p>
		<p>6. We are responsible for providing platform technical service support and also responsible for the settlement of
			platform service fees (if any). </p>

		<h3>Article 3 Your Rights and Obligations</h3>
		<p>1. You should use real identity information and personal data, and shall not use false or fraudulent resident
			identity information, business registration information, or organization code information for registration and
			authentication. If there are any changes to your personal information, you should update it promptly. We prohibit
			live broadcasting by minors. If the legal guardian wants minors to provide webcasting and live streaming services
			stipulated in this agreement, they must judge whether the content of such services is suitable for minors as a
			legal guardian, and the legal guardian must Bear all consequences resulting from this. You promise not to harm the
			legitimate interests of any third party by executing this agreement. Your acceptance and performance of this
			agreement will not violate any legal document that is binding on you, nor will it make us liable to any third
			party. </p>
		<p>2. You are responsible for the network, video and voice support equipment required for live broadcast, and ensure
			that the live broadcast image is clear and the voice quality is clear and stable. </p>
		<p>3. You promise that you are willing to follow the live broadcast room application procedures set by the platform,
			submit the application materials required by the platform and voluntarily pay the corresponding deposit. </p>
		<p>4. You promise that the live broadcast room must be used for live broadcast and shall not be used for any other
			non-live broadcast activities. <b>When you carry out live streaming of goods, you and your suppliers should abide
				by Chinese laws and fulfill the relevant provisions of the "Merchant Service Agreement" promulgated by us. </b>
		</p>
		<p>5. All information/information/speeches/content you carry out under this Agreement and/or publish on this
			platform shall not contain any content that violates the relevant laws, regulations and provisions of the People's
			Republic of China. Including but not limited to content that endangers national security, is obscene, false,
			illegal, defamatory (including commercial libel), illegally intimidating or illegally harassing, infringes upon
			others’ intellectual property rights, personal rights, trade secrets or other legitimate rights and interests, and
			is against public order and good morals or points to these content Links to content. </p>
		<p>6. You promise to actively maintain the image of us and our platform, and you will not take actions that damage
			the image or interests of us and/or our platform. During the period of this agreement and after the termination of
			the agreement, You will not imply or publish remarks that are unfavorable to us and/or our platform through any
			channels (including but not limited to websites, blogs, Weibo, WeChat, QQ chat groups, player gatherings, etc.).
		</p>
		<p>7. Without our written consent, you may not mention the relevant information of third-party competitive platforms
			on any occasion in any form (including but not limited to text, oral broadcast, video patch, etc.), and may not
			guide or provide Existing users of our platform, other live broadcast parties and our employees enter other
			third-party competitive platforms to provide any information or convenience, including but not limited to
			providing contact assistance, persuasion, etc. </p>

		<h3>Article 4 Service Fees and Settlement</h3>
		<p>1. On the premise that you provide live broadcast services for platform users, users can give you virtual gifts
			for consumption, and you can apply for settlement-related income (if any) in accordance with our settlement
			requirements and rules. We We will charge a certain percentage of the technical service fee and pay it to you
			after deducting the corresponding taxes. We have the right to independently judge and handle the consumption of
			virtual gifts obtained through abnormal means. </p>
		<p>2. The service fees you receive should be taxed in accordance with relevant national laws and regulations. We
			will pay the service fees you receive to the bank account you filled in in the user center. You can log in Query
			relevant information in the revenue records on our platform (settlement data includes tax data). </p>
		<p>3. If you are a natural person and have registered on our platform and passed personal authentication, we have
			the right to pay the service fees you receive into the personal bank account you filled in the user center; If you
			are a legal person or other organization and have registered on our platform and passed agency certification, we
			have the right to pay the service fees you receive into the agency account you filled in on the agency
			certification page, but you You should provide us with a valid value-added tax special invoice for the same amount
			(the name of the invoice is live broadcast service fee) within 5 working days before our payment. If your delay in
			providing the invoice results in a delay in our payment, it does not constitute a breach of contract on our part.
			When we pay the service fee according to the account filled in by you, we are deemed to have fulfilled our payment
			obligations stipulated in this agreement. If you are a legal person or other organization, the expenses incurred
			by your staff or affiliated anchors for the management and operation of the account and its live broadcast room
			shall be settled between you and your staff or affiliated anchors. If you cause disputes, lawsuits or compensation
			to cause losses to us due to the settlement of fees (including but not limited to when you default on the salary
			fees of your staff or anchors, we advance their salaries), we We have the right to deduct the amount of service
			fees payable in advance, and we have the right to recover the shortfall from you. </p>
		<p>4. You guarantee that the account information you fill in is correct, authentic, and valid. If any payment errors
			on our part are caused by the account information, you will be solely responsible. At the same time, if you need
			to change your account information, you must notify us in writing in time. The new account information will take
			effect in the next settlement month after you submit the application and it is reviewed and approved by us. </p>

		<h3>Article 5 Confidentiality System</h3>
		<p>1. You should strictly abide by our confidentiality system and promise to keep our business secrets indefinitely.
			If you use or disclose our trade secrets and information in violation of the agreement and cause us to suffer any
			direct or indirect losses to our reputation, reputation or economy, you shall compensate us for liquidated damages
			of RMB [100,000] yuan, which is not enough to make up for the loss. If we suffer losses, you shall also compensate
			us for our losses. </p>
		<p>2. Trade secrets refer to those provided by us, or learned by you during the cooperation between the two parties,
			or that we assume confidentiality obligations to third parties, which are related to our business and can bring us
			Economic interests, all information that is practical and not publicly known, including (but not limited to):
			technical information, business information and information and documents related to our administrative management
			(including the contents of this agreement and related agreements), your The amount and settlement method,
			standard, rights ownership method, authorization method, customer list, list of other live broadcasters, contact
			information, service fees, list of our staff, etc. that we obtain are not known to the public. </p>
		<p>3. You shall strictly abide by this agreement and shall not use our business secrets without our written
			authorization or consent:</p>
		<ul>
			<li>(1) Disseminate or leak to third parties or unspecified public in any way;</li>
			<li>(2) Use our trade secrets for purposes other than the purposes of this agreement. </li>
		</ul>
		<p>4. After the termination of this agreement, you should return all our trade secrets to us, or destroy all
			documents recording our trade secrets under our supervision. </p>
		<p>5. The provisions of this article will remain effective after the termination of this agreement. </p>

		<h3>Article 6 Change, revocation and termination of the agreement</h3>
		<p>1. We have the right to change, suspend, or terminate this agreement when necessary, and notify you on the
			relevant page. Once the changed agreement is published on the relevant page, it will effectively replace the
			original agreement. After the terms of this agreement are changed, if you continue to provide live broadcast and
			other live broadcast services to our platform users, you will be deemed to have been aware of and accepted the
			changed agreement. If you do not agree with any changes we make to this Agreement, you should immediately notify
			us in writing and stop any live broadcast services on our platform. </p>
		<p>2. Both parties can terminate the agreement by reaching consensus on the termination of this agreement. </p>
		<p>3. If you have any of the following circumstances, we can terminate this agreement immediately without prior
			notice:</p>
		<ul>
			<li>(1) We find that you have violated the statements and commitments made in this agreement;</li>
			<li>(2) Your actions directly or indirectly cause significant damage to our interests;</li>
			<li>(3) Violation of national laws and regulations;</li>
			<li>(4) Violation of other obligations stipulated in this agreement;</li>
			<li>(5) Performing this agreement in a manner that does not meet our requirements (even if it does not constitute
				a breach of contract) such as passiveness or inaction, and fails to make corrections within 10 days after being
				notified by us;</li>
			<li>(6) Due to the occurrence of abnormal circumstances, we believe that you are not suitable to provide services
				under this agreement, and the abnormal circumstances have not been eliminated within 10 days after our
				notification;</li>
			<li>(7) Due to our business adjustments, we will no longer provide live broadcast services. </li>
		</ul>
		<p>4. Due to the cancellation or termination of the agreement due to paragraphs 1 and 2 of Article 6 of this
			Agreement, we will settle the service fees with you in accordance with the provisions of Article 4 of this
			Agreement and the real-time policies of our platform. </p>
		<p>5. Due to the rescission or termination of the agreement caused by paragraph 3 of Article 6 of this agreement, we
			have the right to deduct all unsettled service fees from your account, and have the right to require you to bear
			liability for breach of contract as agreed. </p>

		<h3>Article 7 Liability for breach of contract</h3>
		<p>1. Any untrue statement or violation of your stated commitments that results in other parties filing lawsuits or
			claims against us and/or causing damage to our reputation, you will bear all direct consequences incurred by us.
			and indirect costs, losses and compensation, including the relevant costs and attorney's fees paid by us for the
			litigation. </p>
		<p>2. Unless otherwise agreed in this Agreement, if you violate any provision under this Agreement or the services
			you provide do not meet our requirements, we have the right to unilaterally take corresponding restrictive or
			punitive measures, including but not Limited to: restricting, closing, recycling, or terminating your use of the
			UniLive live broadcast room, restricting or stopping an individual service (such as video live broadcast) and
			deciding whether to resume use based on the actual situation, deducting the unsettled service fees from your
			account . </p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>